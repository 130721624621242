import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const AboutPage = (props) => {
  return (
    <div>
      <Layout>
        <Seo
          title="About - 1st Call Junk Haul Sacramento"
          description="1st Call Junk Haul Sacramento is a local Greater Sacramento Junk Hauling and Junk Removal Company offering Junk Removal, Junk Hauling, and other services with the best prices in town."
          keywords={["1st Call Junk Haul Sacramento"]}
          location={props.location.pathname} />
        <div>
          <div className="relative container mx-auto text-white">
            <div className="absolute z-10 inset-x-0 py-10 sm:pt-12 sm:pb-10 md:py-16 px-10 text-center lg:text-left pl-10">
              <h1 className="font-bold text-3xl md:text-5xl mx-auto">
                About Us
              </h1>
              <p className="pt-5 md:pt-10 md:text-md lg:text-lg max-w-lg md:max-w-2xl mx-auto lg:mx-0">
                At 1st Call Junk Haul Sacramento, we focus strictly on client
                satisfaction and providing services to meet all your needs, around your schedule!
                Our team of professionals take pride in their skills relating to all aspects of Junk Hauling & Junk Removal!
              </p>
              <ul className="pl-1 pt-5 space-y-2 md:text-md lg:text-lg">
                <p>1st Call Junk Haul Sacramento Specializes In:</p>
                <li>• Junk Hauling</li>
                <li>• Junk Removal</li>
                <li>• Dirt & Rock Removal</li>
                <li>• Green Waste Removal</li>
                <li>• Property Cleanout</li>
                <li>• Commercial Services</li>
              </ul>
              <AnchorLink to="/#services" stripHash title="View Services" alt="View Services offered by 1st Call Junk Haul Sacramento" >
                <div className="mt-7">
                  <button className="relative h-10 py-2 px-5 mx-auto transition-colors rounded-lg border border-green focus:shadow-outline bg-green text-white hover:shadow-lg hover:shadow-green font-semibold transition duration-150 ease-in-out">
                    <span>View Services</span>
                  </button>
                </div>
              </AnchorLink>
              <div className="pt-7">
                <p>Call Or Text Us Today!</p>
                <a className="text-lg md:text-xl font-bold hover:text-green" href="tel:916-591-3803" title="Call 1st Call Junk Haul Sacramento" alt="Call 1st Call Junk Haul Sacramento">(916)591-3803</a>
              </div>
            </div>
          </div>
          <StaticImage src="../images/about-us.png" placeholder="none" className="w-screen h-[700px] md:h-[690px] lg:h-[750px]" title="About 1st Call Junk Haul Sacramento" alt="About 1st Call Junk Haul Sacramento" />
        </div>

        <div className="bg-bggray py-10 px-5 lg:px-10">
          <div className="container mx-auto">
            <div className="md:grid md:grid-cols-2">
              <div className="px-6">
                <h2 className="font-bold text-3xl">About 1st Call Junk Haul Sacramento</h2>
                <h3 className="font-semibold text-2xl py-5">One-Stop-Shop Sacramento Junk Haul & Junk Removal Experts</h3>
                <p className="text-lg">1st Call Junk Haul Sacramento is a local Sacramento full-service Junk Haul & Junk Removal company specializing in Junk Removal, Dirt & Rock Removal, Green Waste Removal, Property Cleanout and Commercial Services to get your property looking exactly how YOU want it!</p>
                <h3 className="font-semibold text-2xl py-5">Our Commitment to Quality</h3>
                <p className="text-lg">1st Call Junk Haul Sacramento is built on standards and values that go beyond simply getting the job done. Our priorities and core values are to ensure that
                  your expectations are exceeded with every service that we provide.</p><br />
                <p className="text-lg">The dedication to client satisfaction that we have is shown throughout the Greater-Sacramento Area by our growing reputation of providing professional
                  Junk Haul & Junk Removal services at an affordable price and letting the client choose what day & time works best for them. You can count on us to get the job done, because there is no job that is too small or too big for 1st Call Junk Haul Sacramento!</p>
                <h3 className="font-semibold text-2xl py-5">We Guarantee Your Satisfaction!</h3>
                <p className="text-lg">Our team of professionals is the most valuable asset to 1st Call Junk Haul Sacramento. We pride ourselves with our growing reputation of providing quality services to our customers. Whether we are helping you move or clear your property, you can count
                  on us to handle your items and property with the upmost care and responsibility. 1st Call Junk Haul Sacramento guarantees to get the job done right the first time!</p>
                <h3 className="font-semibold text-2xl py-5">FREE & NO-OBLIGATION Quote</h3>
                <p className="text-lg">We offer you the option of receiving a free, no obligation quote for the services that you are looking for. The price that we quote is the price that we honor without adding any hidden fees when the final invoice is provided.</p><br />
                <p className="text-lg">Any questions that you might have will be gladly answered and you will also receive professional advice regarding your project</p>
              </div>

              <div className="pt-10">
                <div className="flex justify-center">
                  <div className="border rounded-xl w-[32rem] md:w-96 h-fit shadow-2xl bg-green">
                    <p className="font-bold px-12 py-3 text-3xl">About Us</p>
                    <hr className="border" />
                    <div className="bg-white px-12 text-lg rounded-b-xl">
                      <h4 className="font-bold py-2 pt-6">Professional Services:</h4>
                      <p className="">All things Junk Hauling & Junk Removal</p>
                      <h4 className="font-bold py-2 pt-6">Contact Us</h4>
                      <p className=""><b>Phone: </b><a className="hover:text-green" href="tel:916-591-3803">(916) 591-3803</a></p>
                      <h4 className="font-bold py-2 pt-6 ">Locations Served:</h4>
                      <p className="pb-6">Greater Sacramento & Surrounding Areas</p>
                    </div>
                  </div>
                </div>
                <div className="flex justify-center">
                  <div className="border bg-green rounded-xl w-[32rem] md:w-96 h-fit shadow-2xl mt-10">
                    <p className="font-bold px-12 py-3 text-3xl text-white">Why Us</p>
                    <hr className="border" />
                    <div className="bg-white px-12 text-lg rounded-b-xl">
                      <h4 className="font-bold py-2 pt-6">Highest Quality Work For Every Job</h4>
                      <p className="">We guarantee to exceed your expectations</p>
                      <h4 className="font-bold py-2 pt-6">Local Company</h4>
                      <p className="">We are available to provide you any service WHENEVER you need</p>
                      <h4 className="font-bold py-2 pt-6 ">Free & No Obligation Quote</h4>
                      <p className="">Know exactly what the service will cost you before the work begins</p>
                      <h4 className="font-bold py-2 pt-6 xl:hidden">All Inclusive / Full Responsibility</h4>
                      <p className="xl:hidden">We take responsibility for all of our work</p>
                      <h4 className="font-bold py-2 pt-6 xl:hidden">Multi-Service Discount</h4>
                      <p className="xl:hidden">Recieve better rates with more services</p>
                      <h4 className="font-bold py-2 pt-6 ">Affordable Pricing</h4>
                      <p className="pb-6">Professional work with the most affordable prices</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}
export default AboutPage
